.spv-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px 50px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;

  color: #555;
  /*border: 1px solid black;*/

  display: flex;
  flex-direction: column;
  align-items: center;
}

.spv-report .logo img {
  width: 95px;
  height: 40px;
}

.spv-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-bottom: 25px;
}

/* Profit setting information */
.spv-report .baseTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .baseTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .baseTable > tbody > tr > th {
  width: 22%;
  font-weight: bolder;
  text-align: left;
  padding: 10px 0px 10px 20px;
}
.spv-report .baseTable > tbody > tr > td {
  width: 28%;
  border-collapse: collapse;
  padding: 10px 0px 10px 0px;
}

/* Each Shareholder summary */
.spv-report .shareholderTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .shareholderTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .shareholderTable > tbody > tr > th {
  text-align: right;
  padding: 5px 0px;
}
.spv-report .shareholderTable > tbody > tr > td {
  width: 12.5%;
  text-align: right;
  padding: 3px 0px;
}

/* Each PV summary */
.spv-report .pvTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .pvTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .pvTable > tbody > tr > th {
  width: 22%;
  font-weight: bolder;
  text-align: left;
  padding: 10px 0px 10px 20px;
}
.spv-report .pvTable > tbody > tr > td {
  width: 28%;
  border-collapse: collapse;
  padding: 10px 0px 10px 0px;
}
.spv-report .holderTable {
  margin-left: 30px;
  width: 650px;
  max-width: 650px;
}
.spv-report .holderTable > tbody > tr {
  text-align: right;
}

.spv-report .holderTable > tbody > tr > td {
  width: 25%;
  text-align: right;
  padding: 3px 0px;
}
