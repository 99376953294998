.bill-preparing-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-preparing-report .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-preparing-report .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-preparing-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}

.bill-preparing-report table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.bill-preparing-report table td {
  padding: 5px;
  vertical-align: top;
}

.bill-preparing-report table tr td:nth-child(1) {
  padding-left: 20px;
}

.bill-preparing-report table.detail tr td:nth-child(1) {
  width: 18%;
}

.bill-preparing-report table.detail tr td:nth-child(2) {
  width: 13%;
}

.bill-preparing-report table.detail tr td:nth-child(3) {
  width: 30%;
}

.bill-preparing-report table tr td.title {
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0 0 0;
}

.bill-preparing-report table tr.field {
  font-weight: bold;
}
