.fixed-invoice {
  max-width: 1000px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.fixed-invoice .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fixed-invoice .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.fixed-invoice .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.fixed-invoice table {
  border-collapse: collapse;
  width: 100%;
}

.fixed-invoice table,
.fixed-invoice th,
.fixed-invoice td {
  border: 1px solid black;
}

.fixed-invoice table tr td {
  padding-left: 3px;
}

.fixed-invoice table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.fixed-invoice table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}
