.bill-summary-for-owner {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #555;
  /*border: 1px solid black;*/
}

.bill-summary-for-owner .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-summary-for-owner .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-summary-for-owner .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-summary-for-owner .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-summary-for-owner .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.bill-summary-for-owner table {
  border-collapse: collapse;
  width: 100%;
}

.bill-summary-for-owner table,
.bill-summary-for-owner th,
.bill-summary-for-owner td {
  border: 1px solid black;
}

.bill-summary-for-owner table tr td {
  padding-left: 3px;
}

.bill-summary-for-owner table tr.total td {
  text-align: right;
  padding-right: 3px;
  font-weight: bold;
}

.bill-summary-for-owner table tr.total td.totalName {
  text-align: center;
}

.bill-summary-for-owner table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.bill-summary-for-owner table tr td.date {
  text-align: center;
}
