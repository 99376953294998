.bill-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-report .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-report .logo img {
  width: 95px;
  height: 40px;
}

.bill-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}

.bill-report table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.bill-report table td {
  padding: 5px;
  vertical-align: top;
}

.bill-report table tr td:nth-child(1) {
  padding-left: 30px;
  width: 37%;
}

.bill-report table tr td:nth-child(2) {
  text-align: left;
  width: 25%;
}

.bill-report table tr td:nth-child(3) {
  width: 38%;
}

.bill-report table.sign td:nth-child(2) {
  text-align: left;
  width: 32%;
}

.bill-report table.sign td:nth-child(3) {
  width: 18%;
}

.bill-report table.sign td:nth-child(4) {
  width: 18%;
}

.bill-report table tr td.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.8;
  padding: 5px 0 0 0;
}

.bill-report table tr.field {
  font-weight: bold;
}

.bill-report table tr.sign {
  height: 125px;
}

.bill-report table tr.sign td:nth-child(2) {
  text-align: right;
  vertical-align: bottom;
  color: lightgrey;
  border: 1px dashed lightgrey;
}

.bill-report table.sign {
  border-spacing: 0 3px;
}
