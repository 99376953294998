body {
  margin: 0;
  padding: 0;
  font-family: 'Crimson Text', 'Noto Serif TC', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bill-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-report .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-report .logo img {
  width: 95px;
  height: 40px;
}

.bill-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}

.bill-report table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.bill-report table td {
  padding: 5px;
  vertical-align: top;
}

.bill-report table tr td:nth-child(1) {
  padding-left: 30px;
  width: 37%;
}

.bill-report table tr td:nth-child(2) {
  text-align: left;
  width: 25%;
}

.bill-report table tr td:nth-child(3) {
  width: 38%;
}

.bill-report table.sign td:nth-child(2) {
  text-align: left;
  width: 32%;
}

.bill-report table.sign td:nth-child(3) {
  width: 18%;
}

.bill-report table.sign td:nth-child(4) {
  width: 18%;
}

.bill-report table tr td.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.8;
  padding: 5px 0 0 0;
}

.bill-report table tr.field {
  font-weight: bold;
}

.bill-report table tr.sign {
  height: 125px;
}

.bill-report table tr.sign td:nth-child(2) {
  text-align: right;
  vertical-align: bottom;
  color: lightgrey;
  border: 1px dashed lightgrey;
}

.bill-report table.sign {
  border-spacing: 0 3px;
}

.bill-preparing-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-preparing-report .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-preparing-report .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-preparing-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}

.bill-preparing-report table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.bill-preparing-report table td {
  padding: 5px;
  vertical-align: top;
}

.bill-preparing-report table tr td:nth-child(1) {
  padding-left: 20px;
}

.bill-preparing-report table.detail tr td:nth-child(1) {
  width: 18%;
}

.bill-preparing-report table.detail tr td:nth-child(2) {
  width: 13%;
}

.bill-preparing-report table.detail tr td:nth-child(3) {
  width: 30%;
}

.bill-preparing-report table tr td.title {
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0 0 0;
}

.bill-preparing-report table tr.field {
  font-weight: bold;
}

.spv-report {
  max-width: 740px;
  min-height: 1060px;
  margin: auto;
  padding: 30px 50px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;

  color: #555;
  /*border: 1px solid black;*/

  display: flex;
  flex-direction: column;
  align-items: center;
}

.spv-report .logo img {
  width: 95px;
  height: 40px;
}

.spv-report .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-bottom: 25px;
}

/* Profit setting information */
.spv-report .baseTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .baseTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .baseTable > tbody > tr > th {
  width: 22%;
  font-weight: bolder;
  text-align: left;
  padding: 10px 0px 10px 20px;
}
.spv-report .baseTable > tbody > tr > td {
  width: 28%;
  border-collapse: collapse;
  padding: 10px 0px 10px 0px;
}

/* Each Shareholder summary */
.spv-report .shareholderTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .shareholderTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .shareholderTable > tbody > tr > th {
  text-align: right;
  padding: 5px 0px;
}
.spv-report .shareholderTable > tbody > tr > td {
  width: 12.5%;
  text-align: right;
  padding: 3px 0px;
}

/* Each PV summary */
.spv-report .pvTable {
  width: 100%;
  margin-bottom: 50px;
}
.spv-report .pvTable > caption {
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
  border-radius: 5px;
  background-color: #ddd;
  padding: 8px;
}
.spv-report .pvTable > tbody > tr > th {
  width: 22%;
  font-weight: bolder;
  text-align: left;
  padding: 10px 0px 10px 20px;
}
.spv-report .pvTable > tbody > tr > td {
  width: 28%;
  border-collapse: collapse;
  padding: 10px 0px 10px 0px;
}
.spv-report .holderTable {
  margin-left: 30px;
  width: 650px;
  max-width: 650px;
}
.spv-report .holderTable > tbody > tr {
  text-align: right;
}

.spv-report .holderTable > tbody > tr > td {
  width: 25%;
  text-align: right;
  padding: 3px 0px;
}

.bill-summary-for-owner {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #555;
  /*border: 1px solid black;*/
}

.bill-summary-for-owner .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-summary-for-owner .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-summary-for-owner .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-summary-for-owner .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-summary-for-owner .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.bill-summary-for-owner table {
  border-collapse: collapse;
  width: 100%;
}

.bill-summary-for-owner table,
.bill-summary-for-owner th,
.bill-summary-for-owner td {
  border: 1px solid black;
}

.bill-summary-for-owner table tr td {
  padding-left: 3px;
}

.bill-summary-for-owner table tr.total td {
  text-align: right;
  padding-right: 3px;
  font-weight: bold;
}

.bill-summary-for-owner table tr.total td.totalName {
  text-align: center;
}

.bill-summary-for-owner table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.bill-summary-for-owner table tr td.date {
  text-align: center;
}

.bill-summary-for-owner-apli {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-summary-for-owner-apli .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-summary-for-owner-apli .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-summary-for-owner-apli .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.bill-summary-for-owner-apli table {
  border-collapse: collapse;
  width: 100%;
}

.bill-summary-for-owner-apli table,
.bill-summary-for-owner-apli th,
.bill-summary-for-owner-apli td {
  border: 1px solid black;
}

.bill-summary-for-owner-apli table tr td {
  padding-left: 3px;
}

.bill-summary-for-owner-apli table tr.total td {
  text-align: right;
  padding-right: 3px;
  font-weight: bold;
}

.bill-summary-for-owner-apli table tr.total td.totalName {
  text-align: center;
}

.bill-summary-for-owner-apli table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.bill-summary-for-owner-apli table tr td.date {
  text-align: center;
}

.bill-payment {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-payment .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-payment .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-payment .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-payment .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-payment .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.bill-payment table {
  border-collapse: collapse;
  width: 100%;
}

.bill-payment table,
.bill-payment th,
.bill-payment td {
  border: 1px solid black;
}

.bill-payment table tr td {
  padding-left: 3px;
}

.bill-payment table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.bill-payment table tr td.date {
  text-align: center;
}

.bill-payment table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.bill-invoice {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.bill-invoice .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-invoice .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-invoice .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bill-invoice .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.bill-invoice .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.bill-invoice table {
  border-collapse: collapse;
  width: 100%;
}

.bill-invoice table,
.bill-invoice th,
.bill-invoice td {
  border: 1px solid black;
}

.bill-invoice table tr td {
  padding-left: 3px;
}

.bill-invoice table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.bill-invoice table tr td.date {
  text-align: center;
}

.bill-invoice table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.fixed-invoice {
  max-width: 1000px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.fixed-invoice .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fixed-invoice .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.fixed-invoice .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.fixed-invoice table {
  border-collapse: collapse;
  width: 100%;
}

.fixed-invoice table,
.fixed-invoice th,
.fixed-invoice td {
  border: 1px solid black;
}

.fixed-invoice table tr td {
  padding-left: 3px;
}

.fixed-invoice table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.fixed-invoice table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.annual-power-invoice {
  max-width: 1050px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.annual-power-invoice .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.annual-power-invoice .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.annual-power-invoice .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.annual-power-invoice .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.annual-power-invoice .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.annual-power-invoice table {
  border-collapse: collapse;
  width: 100%;
}

.annual-power-invoice table,
.annual-power-invoice th,
.annual-power-invoice td {
  border: 1px solid black;
}

.annual-power-invoice table tr td {
  padding-left: 3px;
}

.annual-power-invoice table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.annual-power-invoice table tr td.date,
table tr td.info {
  text-align: center;
}

.annual-power-invoice table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.annual-power-payment {
  max-width: 1050px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.annual-power-payment .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.annual-power-payment .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.annual-power-payment .logoRfdme {
  width: 100%;
  display: flex;
  justify-content: center;
}

.annual-power-payment .logoRfdme img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.annual-power-payment .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.annual-power-payment table {
  border-collapse: collapse;
  width: 100%;
}

.annual-power-payment table,
.annual-power-payment th,
.annual-power-payment td {
  border: 1px solid black;
}

.annual-power-payment table tr td {
  padding-left: 3px;
}

.annual-power-payment table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.annual-power-payment table tr td.date,
table tr td.info {
  text-align: center;
}

.annual-power-payment table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

.spv-dividend {
  max-width: 1090px;
  min-height: 762px;
  margin: auto;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;

  color: #555;
  /*border: 1px solid black;*/
}

.spv-dividend .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.spv-dividend .logo img {
  width: 95px;
  height: 40px;
  margin-bottom: 10px;
}

.spv-dividend .header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 7px;
}

.spv-dividend table {
  border-collapse: collapse;
  width: 100%;
}

.spv-dividend table,
.spv-dividend th,
.spv-dividend td {
  border: 1px solid black;
}

.spv-dividend table tr td {
  padding-left: 3px;
}

.spv-dividend table tr td.number {
  text-align: right;
  padding-right: 3px;
}

.spv-dividend table tr td.date,
table tr td.info {
  text-align: center;
}

.spv-dividend table tr td.total {
  font-weight: bold;
  text-align: right;
  padding-right: 3px;
}

